<template>
	<w-layout class="w-duration-input" align-center row>
		<w-flex shrink>
			<w-text-input v-model.number="hours" :disabled="disabled" inputmode="number" :label="label" mask="####" placeholder=" " :readonly="readonly" />
		</w-flex>
		<w-flex mx-1 shrink>h</w-flex>

		<w-flex shrink>
			<w-text-input ref="minute-input" v-model="minutes" :disabled="disabled" inputmode="number" mask="##" maxlength="2" :readonly="readonly" />
		</w-flex>
		<w-flex ml-1 shrink>min</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'WDurationInput',
	props: {
		disabled: {
			default: false,
			required: false,
			type: Boolean
		},
		label: {
			default: undefined,
			required: false,
			type: String
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			default: undefined,
			required: false,
			type: Number
		}
	},
	computed: {
		hours: {
			get: function () {
				if (!this.value) {
					return undefined
				}
				return (this.value - (this.value % 60)) / 60
			},
			set: function (val) {
				const hours = val * 60
				this.$emit('input', hours + parseInt(this.minutes ?? '0'))
			}
		},
		minutes: {
			get: function () {
				if (this.value === undefined || this.value === null) {
					return undefined
				}
				let result = (this.value % 60).toString()
				const isFocusedOnInput = this.$refs['minute-input']?.$el?.contains?.(document.activeElement) ?? false
				if (!isFocusedOnInput) {
					result = result.padStart(2, '0')
				}
				return result
			},
			set: function (val) {
				if (!val) {
					val = '0'
				}
				this.$emit('input', (this.hours ?? 0) * 60 + parseInt(val))
			}
		}
	}
}
</script>

<style scoped>
.w-duration-input :deep(input) {
	text-align: right;
}
</style>
